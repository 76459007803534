@import "./../../../variables";

.ui-panel-wrapper {
  .ui-panel {
    background: #fff;
    border-radius: $border-radius;
    //max-width: 625px;
    min-width: 250px;
    padding: 25px;
    border: solid 1px $mid-grey;
    color: #000;
  }
}