@import "../../_ui/colors";

$padding: 15px;

.activity-feed-item:last-of-type {
  .activity-item-footer {
    border: 0;
    border-top: solid 1px #ccc;
  }
}

.activity-feed-item.activity-feed-loader .placeholder,
.avatar-container .placeholder, h2.placeholder {
  animation: blink 2s ease infinite;
  color: #ccc;
  background: #ccc;
  cursor: default;
}
.profile-header.placeholder {
  background: $color-dark-gray;
  animation: blink 2s ease infinite;
}
h2.placeholder {

}

@keyframes blink {
  0% { opacity: 0.5; }
  50% { opacity: 0.2; }
  100% { opacity: 0.5; }
}

.activity-feed-item.muted * {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.75;
  pointer-events: none;
}

.activity-feed-item {
  background: #fff;

  .activity-item-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: $padding;

    .activity-item-tools {
      position: relative;

      .tools-indicator {
        width: 20px;
        height: 20px;
        background-image: radial-gradient(circle, black 2px, transparent 2px);
        background-size: 100% 33.33%;
        cursor: pointer;
      }

      .tools-indicator.placeholder {
        background-image: radial-gradient(circle, #ccc 2px, transparent 2px);
        background-color: inherit;
        cursor: default;
      }

        .dropdown.open {;
          z-index: 1;
          display: flex;
          flex-direction: column;
        }

      .dropdown.open.reversed {;
        z-index: 1;
        display: flex;
        flex-direction: column-reverse;
        top: inherit;
        bottom: -10px;
        -webkit-box-shadow: 0 -5px 15px -10px rgba(0, 0, 0, 1);
        -moz-box-shadow: 0 -5px 15px -10px rgba(0, 0, 0, 1);
        box-shadow: 0 -5px 15px -10px rgba(0, 0, 0, 1);
      }

        .dropdown {
          display: none;
          position: absolute;
          right: -5px;
          text-wrap: nowrap;
          border: solid 1px #ccc;
          border-radius: 20px;
          top: -15px;
          background: #fff;
          overflow: hidden;
          max-width: 300px;

          .dropdown-header {
            line-height: 40px;
            padding: 0 10px;
            font-weight: bold;
            display: flex;
            align-content: center;
            background: $color-light-grey;

            .title {
              text-overflow: ellipsis;
              max-width: calc(100% - 15px);
              overflow: hidden;
            }
          }

          ul li {
            border-top: solid 1px #ccc;

            a {
              display: block;
              padding: 10px;
              text-decoration: none;
              text-overflow: ellipsis;
              //max-width: calc(100% - 15px);
              overflow: hidden;
            }
          }
        }

    }

    .publication-time, .visibility {
      color: #ccc;
    }

    .visibility {
      margin: 0 5px;
    }
  }

  .activity-item-content {
    .content-text {
      padding: 0 $padding $padding;
      line-height: 150%;
      font-size: 20px;
      white-space: pre-line;
      overflow-wrap: break-word;
    }
  }

  .content-challenge {
    padding: $padding;
    background: $color-brand;
    color: #fff;
    font-size: 20px;

    .challenge-header {
      display: flex;
      flex-direction: column;
      padding-left: 75px;
      background-size: 60px 60px;
      background-position: left center;
      background-repeat: no-repeat;

      .challenge-name {
        h3 {
          a {
            text-decoration: none;
            color: #ffffff;
          }
          font-family: "Baloo Bhai 2", cursive;
          font-size: 20px;
          font-weight: bold;
          padding-bottom: 10px;
        }
      }

      .challenge-details {
        font-size: 16px;
        .challenge-details-row {
          span {
            margin-right: 15px;
          }
          span:last-of-type {
            margin-right: 0;
          }
        }
      }
    }

    .challenge-header.basketball {
      background-image: url("https://sportzzy.com/static/media/cycling.9bebc51258cb604cc717.png");
    }
    .challenge-header.running {
      background-image: url("https://sportzzy.com/static/media/cycling.9bebc51258cb604cc717.png");
    }
    .challenge-header.badminton {
      background-image: url("https://sportzzy.com/static/media/cycling.9bebc51258cb604cc717.png");
    }
  }

  .activity-item-footer {
    padding: $padding;
    border: solid 1px #ccc;
    border-radius: 0 0 30px 30px;
    margin: 0 -1px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }
}

.pill.selected {
  background: $color-brand;
  .label {
    color: #fff;
  }
}
.pill.ico-group {
  .ico.selected {
    background: $color-brand;
  }
}

.pill {
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  border: solid 1px $color-brand;
  border-radius: 12px;
  margin-left: 5px;
  .ico {
    text-align: center;
    width: 14px;
    padding: 3px;
    display: inline-block;
    border-radius: 50%;
    border: solid 1px $color-brand;
    margin: -1px;
    background: #fff;
  }
  .label {
    padding: 3px 8px 3px 3px;
  }
}