@import "../../components/_ui/colors";

#video-section {
  position: relative;
  height: 90vh;
  overflow: hidden;
}

video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 95%;
  object-fit: cover;
  z-index: -1;
}

#login-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 5px;
}

#content-section {
  background-color: $color-brand;
  padding: 20px;
  text-align: center;
}