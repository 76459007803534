@import "../../../variables";

.ui-slogan, .ui-slogan span {
  text-align: center;
  font-size: 100px;
  display: inline-block;
  margin: 0 auto;
  color: $dark-gray;
  font-family: 'Baloo Bhai 2', cursive;
  font-weight: bold;
}

//.ui-slogan {
//  margin-top: 20px;
//}

.ui-slogan span.second-line {
  display: block;
  text-align: right;
  font-size: 21px;
  font-family: 'Lato', sans-serif;
  color: $dark-gray;
  font-weight: normal;
  margin-top: -20px;
}

.ui-slogan .first-line .i {
  color: $brand-green;
  position: relative;
}
.ui-slogan .first-line .i:before {
  content: "ı";
  position: absolute;
  color: $dark-gray;
}

/* small devices */
@media only screen and (max-width: 640px) {
  .ui-slogan, .ui-slogan span {
    font-size: 60px;
  }
  .ui-slogan span.second-line {
    font-size: 13px;
    margin-top: -10px;
  }

  .landing-page-form-section .container {
    padding: 25px
  }
}