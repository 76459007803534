@import "./../../../variables";

.columns-layout {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  height: calc(100vh - $navBarHeight);

  main {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 300px;
    padding: 25px;
    overflow-y: scroll;
  }

  .column:last-child {
    padding-right: 50px;
  }

  .column:first-child {
    padding-left: 50px;
  }

  div.column {
    //position: sticky;
    //top: 0;
    overflow-y: scroll;
    height: calc(100vh - $navBarHeight);
    flex-grow: 0;
    flex-shrink: 0;
    width: 30%;
    max-width: 450px;
    //background-color: red;
  }
}