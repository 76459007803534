.cookie-box {
  position: fixed;
  bottom: 15px;
  left: 15px;
  width: 350px;
  background-color: rgba(0, 0, 0, 0.8); /* Transparent background with some opacity */
  border: 1px solid #26e567; /* Solid top border with the specified color */
  border-radius: 20px;
  -webkit-box-shadow: 0 -5px 15px -10px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0 -5px 15px -10px rgba(0, 0, 0, 1);
  box-shadow: 0 -5px 15px -10px rgba(0, 0, 0, 1); /* Shadow effect */
  color: white;
  //display: flex;
  //align-items: center;
  line-height: 150%;

  .content {
    padding: 15px;
    text-align: justify;
    white-space: break-spaces;
  }
  .actions {
    text-align: right;
    padding: 15px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      cursor: pointer;
    }
  }
}