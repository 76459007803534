@import "../_ui/colors";

.activity-feed {
  max-width: 800px;
  min-width: 335px;
  margin: 25px auto;
  border: solid 1px $color-mid-grey;
  border-radius: 20px;
  overflow: hidden;
}

.no-more-content, .no-content {
  padding: 30px 15px;
  background-color: $color-light-grey;
  font-size: 20px;
  text-align: center;
}

.publish-form-content-text {
  padding: 15px 0 15px;
  ul {
    border: solid 1px $color-light-grey;
    border-radius: 20px;
    overflow: hidden;
    margin-top: 20px;
    background-color: white;
    -webkit-box-shadow: 0 5px 15px -10px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0 5px 15px -10px rgba(0, 0, 0, 1);
    box-shadow: 0 5px 15px -10px rgba(0, 0, 0, 1);

    li {
      padding: 5px;
    }

    li:hover {
      background-color: $color-light-grey;
    }
  }
}

.publish-form-content-challenge {
  background: $color-brand;
  margin: 0 -15px 0 -15px;
  padding: 15px;

  .find-discipline-input {
    width: calc(100% - 32px);
    border: solid 1px #000;
    border-radius: 25px;
    padding: 5px 15px;
    font-size: 18px;
  }

  .disciplines-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: flex-start;

    .discipline {
      width: 75px;
      height: 75px;
      margin: 5px;

      label {
        display: block;
        background-color: $color-brand;
        padding: 5px;
        font-size: 12px;
        width: 65px;
        height: 65px;
        cursor: pointer;
        text-align: center;
        border: solid 1px #ffffff;
        border-radius: 20px;
        color: #ffffff;

        transition: all .3s ease-out;
      }

      input[type="radio"] {
        visibility: hidden;
        height: 0;
        width: 0;
      }
      //
      //label {
      //  display: table-cell;
      //  vertical-align: middle;
      //  text-align: center;
      //  cursor: pointer;
      //  background-color: $color-light-grey;
      //  color: white;
      //  padding: 5px 10px;
      //  border-radius: 3px;
      //
      //  transition: all .3s ease-out;
      //}
      //
      input[type="radio"]:checked + label {
        border: solid 1px $color-mid-grey;
        background-color: #ffffff;
        color: $color-dark-gray;
      }
    }
  }


  .steps {
    width: 100%;
    overflow: hidden;
    border-bottom: solid 1px #fff;
    margin: -15px -15px 15px;
    padding: 15px;

    //width: calc(100% - 10px);
    //overflow: hidden;
    //border: solid 1px #fff;
    //border-radius: 20px;
    //margin-bottom: 10px;
    //padding: 5px;
  }

  .horizontal-progress {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .horizontal-progress li {
    flex: 1;
    text-align: center;
    position: relative;
    opacity: 0.5;
  }

  .horizontal-progress li .ico {
    display: block;
    width: 30px;
    height: 30px;
    background-color: #27ae60;
    border-radius: 50%;
    margin: 0 auto 10px;
  }

  .horizontal-progress li .label {
    display: block;
    font-size: 14px;
    color: #ffffff;
  }

  //.horizontal-progress li:after {
  //  content: "";
  //  position: absolute;
  //  top: 15px;
  //  transform: translateY(-50%);
  //  width: 100%;
  //  height: 2px;
  //  background-color: #3498db;
  //}

  .horizontal-progress li.done {
    opacity: 1;
  }

  .horizontal-progress li.done .ico {
    background-color: #27ae60;
  }

  .horizontal-progress li.done .label {
    font-weight: bold;
    color: #ffffff;
  }


}