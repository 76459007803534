@import "../../../../_ui/colors";

.tags {
  a.tag:first-of-type {
    margin-left: 0;
  }

  a.tag {
    text-decoration: none;
    margin-left: 5px;

    span {
      color: $color-brand;
    }
  }
}