//$static-url: env('REACT_APP_STATIC_URL');
$static-url: 'https://static.beta-sportzzy.com';

$navBarHeight: 60px;

$brand-green: #26e567;
$light-grey: #e1e1e1;
$gray: #e7e7eb;
$mid-grey: #ccc;
$dark-gray: #242424;

$border-radius: 25px;