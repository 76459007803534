@import "Avatar";

.avatar-container {
  display: inline-flex;
  align-items: center;

  .avatar-wrapper {
    margin-right: 3px;
  }

  .label {
    display: inline-block;
    flex: 1; /* Allow the label to grow and take available space */
    font-size: calc($height-box-m / 2);
    line-height: 110%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .label.size-s {
    font-size: calc($height-box-s / 2);
  }
  .label.size-m {
    font-size: calc($height-box-m / 2);
  }
  .label.size-l {
    font-size: calc($height-box-l / 2);
  }
  .label.size-xl {
    font-size: calc($height-box-xl / 2);
  }

  .under-label {
    font-size: calc($height-box-m / 3);
    line-height: 105%;
    display: flex;
  }
  .under-label > * {
    margin-right: 5px;
  }

  .under-label.size-s {
    font-size: calc($height-box-s / 3);
  }
  .under-label.size-m {
    font-size: calc($height-box-m / 3);
  }
  .under-label.size-l {
    font-size: calc($height-box-l / 3);
  }
  .under-label.size-xl {
    font-size: calc($height-box-xl / 3);
  }
}