@import "../colors";
@import "../heights";

.avatar-wrapper {
  display: inline-block;
  border: solid 1px $color-brand;
  border-radius: 50%;
  overflow: hidden;

  .avatar {
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: $height-box-m;
    height: $height-box-m;
    border: solid 1px #fff;
    border-radius: 50%;
    overflow: hidden;
    background: #fff;
  }
}

.avatar.size-s {
  width: $height-box-s;
  height: $height-box-s;
}

.avatar.size-m {
  width: $height-box-m;
  height: $height-box-m;
}

.avatar.size-l {
  width: $height-box-l;
  height: $height-box-l;
}

.avatar.size-xl {
  width: $height-box-xl;
  height: $height-box-xl;
}