@import "./../_ui/colors";

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  border-bottom: solid 1px $color-mid-grey;
  background: #fff;
  z-index: 100;

  .wrapper {
    display: flex;
    justify-content: space-between;
    height: 50px;

    .brand {
      display: flex;
      align-items: center;
    }
    .user-nav.menu {
      .nav-contents {
        background-color: $color-light-grey;
        border-color: #000000;

        .section ul {
          margin: 0;
          padding-left: 25px;
          li {
            border: none;
            padding-right: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            svg {
              cursor: pointer;
            }
          }
        }

        ul {
          margin: 0 -10px;
          li:first-of-type {
            margin-top: 0;
          }
          li {
            margin: 5px;
            border: solid 1px #000;
            border-radius: 20px;

            .section-header, a {
              font-size: 18px;
              display: block;
              text-decoration: none;
              padding: 10px;
            }
          }
        }
      }
    }

    .user-nav {
      padding-top: 10px;
      position: relative;

      @media only screen and (max-width: 640px) { //small
        .signed-in-profile.avatar-container .details {
          display: none;
        }
      }
      .nav-items {
        display: flex;
        align-items: center;

        .nav-indicator {
          height: 30px;
          border: solid 1px $color-mid-grey;
          border-radius: 20px;
          line-height: 30px;
          margin-left: 5px;
          padding: 0 10px;
          cursor: pointer;
        }

        .profile-notifications {
          display: flex;
          align-items: center;

          .ico {
            width: 30px;
            height: 30px;
            border: solid 1px $color-brand;
            border-radius: 50%;
            color: $color-brand;
            text-align: center;
            line-height: 30px;
            margin-left: 5px;
            background-color: #ffffff;
            position: relative;
            cursor: pointer;

            .badge {
              position: absolute;
              bottom: -3px;
              right: -3px;
              color: #ffffff;
              background: $color-brand;
              font-size: 8px;
              line-height: 14px;
              width: 14px;
              height: 14px;
              border-radius: 50%;
            }
          }
        }
      }

      .nav-contents {
        overflow: hidden;
        margin: 0 -5px 0 -5px;
        padding: 44px 10px 0;
        border: solid 1px $color-brand;
        border-radius: 20px;
        top: 5px;
        z-index: -1;
        display: none;
        position: absolute;
        right: 0;
        background-color: #ffffff;

        .nav-contents-empty {
          font-size: 50px;
          color: $color-light-grey;
          text-align: center;
          svg {
            margin: 25px 0;
          }
          span {
            font-size: 20px;
            display: block;
            padding-bottom: 25px;
          }
        }

        .notification.viewed {
          border: $color-mid-grey solid 1px;
          filter: grayscale(1);
          a {
            color: $color-mid-grey;
          }
        }

        .notification {
          margin: 0 -5px 5px;
          padding: 10px;
          border: $color-brand solid 1px;
          border-radius: 20px;

          .notification-header {
            display: flex;
            align-content: center;
            justify-content: space-between;
            margin-bottom: 10px;

            time {
              color: $color-mid-grey;
              text-align: right;
            }

            .avatar-wrapper, .avatar-wrapper img {
              //width: 16px;
              //height: 16px;
            }
          }

          .notification-content {
            a {
              text-decoration: none;
            }
          }
        }
      }
    }
    .user-nav.open {
      .nav-contents {
        display: block;
      }
    }
  }
}