@import "../../components/_ui/colors";

.profile-header {
  text-align: center;
  margin: 0 -20px;
  padding: 25px;
  background: $color-brand;

  h2 {
    font-size: 40px;
  }
}