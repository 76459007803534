@import "../_ui/colors";


.language-switch.open {
  .languages {
    display: block;
  }
}

.language-switch {
  position: fixed;
  bottom: 15px;
  right: 0;
  border: solid 1px $color-brand;
  border-right: 0;
  background: #fff;
  border-radius: 20px 0 0 20px;
  padding: 5px;
  display: flex;
  align-items: center;
  margin-left: 5px;

  .indicator {
    line-height: 30px;
    font-size: 16px;
    padding-right: 0;
    border-radius: 50%;
    background: $color-brand;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }
  .languages {
    display: none;

    button.selected {
      border: solid 1px $color-brand;
      border-radius: 15px;
    }

    button {
      line-height: 28px;
      padding: 0 10px;
      margin-left: 5px;
      background:none;
      border:none;
      cursor: pointer;
      font-size: 16px;
    }
  }
}