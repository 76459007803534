@import "./../../../variables";

.ui-modal {
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  padding-top: 200px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */

  .modal-header {
    border-bottom: solid 1px #ccc;
    padding: 10px 25px;
    margin: -25px -25px 15px;
    display: flex;
    //justify-content: space-between;
    justify-content: flex-end;

    .close {
      cursor: pointer;
    }

    h2 {
      padding-bottom: 0;
      width: 100%;
    }
  }

  .ui-panel-wrapper {
    margin: auto;
    width: 50%;
  }

  .action-buttons {
    border-top: solid 1px $mid-grey;
    margin: 15px -25px -25px;
    padding: 10px 25px;
    justify-content: flex-end;
    display: flex;

    .ui-btn {
      margin-right: 10px;
      border-radius: 15px;
      border: solid 1px $mid-grey;
      padding: 10px 20px;
    }

    .ui-btn:last-child {
      margin-right: 0;
    }

    .ui-btn.success {
      background-color: $brand-green;
    }
  }
}

.ui-btn {
  padding: 10px 20px;
}