body {
  background: #e1e1e1;
  font-family: Lato,sans-serif;
  color: #000;
}

h1, h2, h3, h4, h5 {
  font-family: "Baloo Bhai 2", cursive;
  font-weight: bold;
  padding-bottom: 15px;
}

.shadow {
  -webkit-box-shadow: 0 5px 15px -10px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0 5px 15px -10px rgba(0, 0, 0, 1);
  box-shadow: 0 5px 15px -10px rgba(0, 0, 0, 1);
}

.main-content {
  padding-top: 50px;
}

main {
  padding: 0 20px;
}